import classNames from 'classnames'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Else, If, Then, When } from 'react-if'
import InfiniteScroll from 'react-infinite-scroll-component'
import useBatchCarrier from 'src/hooks/business/use_batch_carrier'
import useCarrierHistory from 'src/hooks/business/use_carrier_history'
import useCarrierHot from 'src/hooks/business/use_carrier_hot'
import useI18n from 'src/hooks/use_i18n'
import { IKV } from 'src/types'
import CarrierImage from './carrier_image'
import styles from './index.module.scss'

interface Props {
  show: boolean
  onClose: () => void
  info: IKV
  setInfo: (info: IKV) => void
}

export default function CarrierModal(props: Props) {
  const { show, onClose, info, setInfo } = props
  const { $t, activeLocale: locale } = useI18n()
  const [focus, setFocus] = useState<boolean>(false)
  const ResGCountryDictRef = useRef<IKV>($t('ResGCountry').items.itemsDict)

  const { debouncedValue, searchInfo, setSearchInfo, fetchMoreData } = useBatchCarrier()
  const { carrierHistoryList, cacheCarrierHistory } = useCarrierHistory() // 搜索历史
  const { carrierHotList } = useCarrierHot() // 热门运输商

  // 渲染国家
  const renderCounntry = (item) => {
    const keys = item?._country_iso || '0'
    const countryObj = ResGCountryDictRef.current.get(keys)
    return <span className="line-clamp-1">{countryObj?._name}</span>
  }

  // 渲染运输商名称
  const renderName = (item) => {
    return item?.['_name_' + locale] || item?._name
  }

  // 渲染运输商展示信息
  const renderText = (item) => {
    if (item?._country_iso) {
      return (
        <>
          {renderCounntry(item)}
          <span className="line-clamp-1">{renderName(item)}</span>
        </>
      )
    } else {
      return <span className="line-clamp-1">{renderName(item)}</span>
    }
  }

  // 同步输入
  const handleInput = (e) => {
    setSearchInfo({ inputVal: e.target.value })
  }

  // 关闭弹窗
  const handleClose = () => {
    onClose()
  }

  // 选择指定运输商
  const handleSel = (item) => {
    window.ga?.sendEvent?.('功能操作', '全局-指定运输商', item.key)
    setInfo(item)
    cacheCarrierHistory(String(item.key))
    handleClose()
  }

  // 系统自动识别运输商
  const handleAutoIdentify = () => {
    window.ga?.sendEvent?.('功能操作', '全局-自动识别运输商')
    setInfo({})
    handleClose()
  }

  // 渲染运输商信息
  const renderListGroup = (dataSource) => {
    return (
      <Container>
        <Row>
          <ul className={styles['list-group']}>
            {dataSource?.map((ele) => {
              return (
                <Col lg={4} md={4} sm={12} xs={12} key={ele.key}>
                  <li className={styles['list-group-item']}>
                    <a onClick={() => handleSel(ele)} className="flex items-center">
                      <CarrierImage
                        src={`https://res.17track.net/asset/carrier/logo/34x34/${ele.key}.png`}
                        alt={renderName(ele)}
                        className={styles['carrier-logo']}
                        width={34}
                        height={34}
                      />
                      <span className="flex flex-col pl-1.5">{renderText(ele)}</span>
                    </a>
                  </li>
                </Col>
              )
            })}
          </ul>
        </Row>
      </Container>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName={styles['dialog-carrier']}
      contentClassName={styles['carrier-content']}
    >
      <div className={classNames('flex items-center justify-between', styles['modal-header'])}>
        <h4>{$t('ResGCompYQInput.global.__dialogCarrier_title')}</h4>
        <div className={classNames('flex items-center', styles['input-search'], { [styles.focus]: focus })}>
          <i className={classNames('fa-search', styles['search-icon'])}></i>
          <input
            type="text"
            className={styles['input-control']}
            title={$t('ResGCompYQInput.global.__dialogCarrier_searchTips')}
            placeholder={$t('ResGCompYQInput.global.__dialogCarrier_searchPlackholder')}
            onInput={handleInput}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={searchInfo.inputVal}
          />
          <When condition={debouncedValue}>
            <button
              className={classNames('fa-times-circle', styles['input-search-close'])}
              onClick={() => setSearchInfo({ inputVal: '' })}
            ></button>
          </When>
          <div className={classNames('flex items-center justify-center', styles.close)} onClick={handleClose}>
            <span>×</span>
          </div>
        </div>
      </div>
      <div className={styles['modal-body']}>
        <If condition={debouncedValue && !searchInfo.showList?.length}>
          <Then>
            <div className={classNames('flex items-center flex-col', styles['empty-data'])}>
              <h4 className="text-center">{$t('ResGCompYQInput.global.__dialogCarrier_searchNoResult')}</h4>
              <Image
                src="https://res.17track.net/global-v2/imgs/illustration/160x160/no-nums.png"
                alt="17TRACK"
                width={160}
                height={160}
              />
            </div>
          </Then>
          <Else>
            <When condition={!debouncedValue}>
              <div className={styles['auto-detect']}>
                <span className={styles['current-detect']}>
                  {$t('ResGCompYQInput.global.__dialogCarrier_currentItem')}：
                </span>
                <div className="flex-1 flex justify-between">
                  <When condition={info?._name}>
                    <div className={classNames('flex', styles['current-info'])}>
                      <Image
                        src={`https://res.17track.net/asset/carrier/logo/34x34/${info.key}.png`}
                        alt={renderName(info)}
                        width={34}
                        height={34}
                      />
                      <span>{renderName(info)}</span>
                    </div>
                  </When>
                  <a
                    className={classNames('cursor-pointer', styles['datect-btn'], { [styles.selected]: info?._name })}
                    onClick={handleAutoIdentify}
                    title={$t('ResGCompYQInput.global.__dialogCarrier_autoDesc')}
                  >
                    <span className={styles['datect-logo']}>
                      <i className="fa-auto"></i>
                    </span>
                    <span className={styles['datect-desc']}>
                      <span>{$t('ResGCompYQInput.global.__dialogCarrier_autoTitle')}</span>
                    </span>
                  </a>
                </div>
              </div>
            </When>

            <div className={classNames('custom-scroll', styles['scrollable-content'])} id="scrollable-content">
              <InfiniteScroll
                dataLength={searchInfo.showList.length}
                next={fetchMoreData}
                hasMore={searchInfo.hasMore}
                loader={<div></div>}
                scrollableTarget="scrollable-content"
              >
                <When condition={!debouncedValue}>
                  {/* 搜索历史 */}
                  <When condition={carrierHistoryList.length}>
                    <div className={classNames(styles.wrapper)}>
                      <h6>{$t('ResGCompYQInput.global.__dialogCarrier_historyTitle')}</h6>
                      {renderListGroup(carrierHistoryList)}
                    </div>
                  </When>

                  {/* 热门运输商 */}
                  <div className={classNames(styles.wrapper)}>
                    <h6>{$t('ResGCompYQInput.global.__dialogCarrier_popularCarriers')}</h6>
                    {renderListGroup(carrierHotList)}
                  </div>
                </When>

                {/* 全部运输商 */}
                <div className={classNames(styles.wrapper)}>
                  <When condition={!debouncedValue}>
                    <h6>{$t('ResGCompYQInput.global.__dialogCarrier_allCarriers')}</h6>
                  </When>
                  {renderListGroup(searchInfo.showList)}
                </div>
              </InfiniteScroll>
            </div>
          </Else>
        </If>
      </div>
    </Modal>
  )
}
