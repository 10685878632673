import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import useI18n from '../use_i18n'

// 批量获取运输单号
export default function useBatchNums() {
  const [nums, setNums] = useState<string>('') // 运输单号信息
  const [TRACK_LENGTH] = useState(500)
  const { $t, i18nFormat } = useI18n()

  // 查询数量信息
  const badgeInfo = useMemo(() => {
    const arr = nums?.split('\n') || []

    return {
      show: (arr.length === 1 && arr[0]) || arr.length > 1,
      num: arr.length
    }
  }, [nums])

  /**
   * 过滤传入的文本内容，校验通过返回正确的数据
   * @param text {String} 需校验的文本内容
   * @param fullScreenMsg {Boolean} 提示文本是否全屏显示
   * @param callback {Function} 回调函数
   */
  function filterNumbers(text: any, callback: (data) => void) {
    const uniqueArr: string[] = []
    let isSupportAirTrack = false
    if (['www.17track.net', 't.17track.net'].includes(location.host)) {
      isSupportAirTrack = true
    }
    const TRACK_NUMBER_REG = isSupportAirTrack
      ? /\s(\d{3}-\d{8})|([a-zA-Z0-9]{0,10}[0-9]{6,20}[a-zA-Z0-9]{0,10})\s/g
      : /\s[a-zA-Z0-9]{0,10}[0-9]{6,20}[a-zA-Z0-9]{0,10}\s/g // 单号获取正则

    const trackFilterReg = isSupportAirTrack ? /[^A-Za-z0-9\-]/g : /[^A-Za-z0-9]/g

    let inputVal = text.replace(trackFilterReg, '  ') // 所有不是字母数字的内容替换成两个空格
    inputVal = ' ' + inputVal + ' ' // 在内容前后添加空格

    let execRusultsArr = inputVal.match(TRACK_NUMBER_REG)

    if (execRusultsArr && execRusultsArr !== null) {
      // 匹配的数据为数组，删除每项内容的前后空格
      execRusultsArr = execRusultsArr.map((ele) => ele.trim(ele))
      // 数组去重
      execRusultsArr.forEach((ele) => {
        if (!uniqueArr.includes(ele)) {
          uniqueArr.push(ele)
        }
      })
    }

    if (uniqueArr.length === 0) {
      toast.warn($t('ResGCompYQInput.global.__msg_filterNoResult'))
      // YQToastr.showWarn({
      //   content: V5Front.ResGCompYQInput.global.__msg_filterNoResult
      // });

      callback({ success: false })
    } else {
      callback({
        success: true,
        filterNumsArr: uniqueArr
      })
    }
  }

  /**
   * 验证传入文本内容，校验通过返回正确的数据
   * @param text {String} 传入验证文本内容
   * @param maxLines {Number} 最大行数设置
   * @param maxLines {Boolean} 提示文本是否全屏显示
   * @param carrierKey {String} 运输商KEY
   * @param callback {Function} 回调函数，用于获取筛选后的数据及验证的状态
   */
  function getNumbers(text: string | string[], callback: (data) => void) {
    // 1、字符串转换成数组处理，获取数组的长度
    // 2、遍历数组，校验数据
    // 3.1 循环体内判断每项的字符是否为空，continue
    // 3.2 判断每项是否存在有非法字符，如果长度为1，提示xxx，如果长度大于1,提示yyyy + (索引号 + 1)
    // 3.3 判断每项长度范围,如果长度为1，提示xxx，如果长度大于1,提示yyyy + (索引号 + 1)
    // 4、删除数组内所有空行内容
    // 5、数组去重
    // 6、判断数组长度是否超出限制
    // 6.1 超过限制，弹窗提示后，回调确定后处理
    // 取得实际数组内容
    let inputArr: string | string[] = []
    if (typeof text === 'string') {
      inputArr = text.split('\n')
    } else if (Array.isArray(text)) {
      inputArr = text
    } else {
      inputArr = []
    }

    // 验证所有行
    const success = verifyLines(inputArr, callback)
    if (!success) {
      return
    }

    // 去重去空行
    inputArr = filterArrDeal(inputArr)

    // 没有输入单号的情况下，提示内容为空
    if (inputArr.length === 0) {
      toast.warn($t('ResGCompYQInput.global.__msg_enterNoNums'))
      // YQToastr.showWarn({
      //   content: V5Front.ResGCompYQInput.global.__msg_enterNoNums
      // })

      callback({ success: false })
      return
    }

    // 当输入的单号行数超出40行时，将询问用户是否只保留前40行的数据
    // if (inputArr.length > 40) {
    //   YQModal.showWarn({
    //     fill: fullScreenMsg,
    //     title: V5Front.ResGCompYQInput.global.__dialogExceed_title,
    //     content: V5Front.ResGCompYQInput.global.__dialogExceed_content.format([maxLines]),
    //     btns: [{
    //       btn: V5Front.ResGCompYQInput.global.__dialogExceed_btnCancel, callback: function () {
    //         callback({ success: false });
    //       }
    //     }, {
    //       btn: V5Front.ResGCompYQInput.global.__dialogExceed_btnConfirm, callback: function () {

    //         inputArr = inputArr.slice(0, maxLines);
    //         var filterData = {
    //           success: true,
    //           carrier: carrierKey,
    //           numbers: inputArr
    //         }
    //         callback(filterData);
    //       }
    //     }]
    //   })
    //   return
    // }

    const data = {
      success: true,
      filterNumsArr: inputArr
    }
    callback(data)
  }

  /**
   * 验证数组每项数据
   * @param lines {array} 需要验证的数组
   * @param maxLines {Boolean} 提示文本是否全屏显示
   * @param callback {function} 回调函数
   * @returns {boolean} 验证通过后，返回true
   */
  function verifyLines(lines: string[], callback: (data) => void) {
    // 是否只有一行
    const isOneLine = lines.length < 2

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]

      // 每项内容空行不检测
      if (isNullOrEmpty(line)) {
        continue
      }

      // 验证每行，失败退出
      const success = verifyLine(line, i, isOneLine, callback)
      if (!success) {
        return false
      }
    }

    // 所有行验证通过
    return true
  }

  /**
   * 验证传入的文本内容，主要验证：1、是否存在非法输入值。2、长度限制验证
   * @param line {string} 验证的文本内容
   * @param lineIndex {number} 当前文本对应数组的索引值
   * @param isOneLine {boolean} 是否为首行
   * @param maxLines {Boolean} 提示文本是否全屏显示
   * @param callback {function} 回调函数
   * @returns {boolean} 全部验证通过后，返回true
   */
  function verifyLine(line: string, lineIndex: number, isOneLine: boolean, callback: (data) => void) {
    // 检查每项值是否有非法输入值
    if (!isNormalStr(line)) {
      let content = ''

      if (isOneLine) {
        content = $t('ResGCompYQInput.global.__msg_illegal')
        // content = V5Front.ResGCompYQInput.global.__msg_illegal;
      } else {
        content = i18nFormat($t('ResGCompYQInput.global.__msg_illegalForRow'), [lineIndex + 1])
        // content = V5Front.ResGCompYQInput.global.__msg_illegalForRow.format([lineIndex + 1]);
      }

      toast.warn(content)

      // YQToastr.showWarn({
      //     content: content
      // });

      callback({ success: false })
      return false
    }

    // 单行字符长度限制验证，不得小于5和大于50
    if (!isNormalLen(line)) {
      let contentTips = ''
      if (isOneLine) {
        contentTips = $t('ResGCompYQInput.global.__msg_character')
        // contentTips = V5Front.ResGCompYQInput.global.__msg_character;
      } else {
        contentTips = i18nFormat($t('ResGCompYQInput.global.__msg_characterForRow'), [lineIndex + 1])
        // contentTips = V5Front.ResGCompYQInput.global.__msg_characterForRow.format([lineIndex + 1]);
      }

      toast.warn(contentTips)

      // YQToastr.showWarn({
      //   content: contentTips
      // });

      callback({ success: false })
      return false
    }

    return true
  }

  /**
   * 数组筛选处理（去重和删除空行）
   * @param lines
   * @returns {Array} 筛选后的正确数组
   */
  function filterArrDeal(lines) {
    const uniqueArr: string[] = []

    for (let i = 0, inputLen = lines.length; i < inputLen; i++) {
      const line = lines[i]

      // 空行跳过
      if (isNullOrEmpty(line)) {
        continue
      }

      // 已在筛选的数组内跳过
      // if ($.inArray(line,uniqueArr) !== -1 ) {
      //     continue;
      // }
      if (uniqueArr.includes(line)) {
        continue
      }

      // 添加到最终数组
      uniqueArr.push(line.replace(/\s/g, '').toUpperCase())
    }

    return uniqueArr
  }

  /**
   * 判断是否存在空行内容
   * @param text
   * @returns {boolean}
   */
  function isNullOrEmpty(text: string) {
    const NULLREG = /\s/g

    if (typeof text === 'string') {
      if (!text) {
        return true
      } else if (text.replace(NULLREG, '').length === 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  /**
   * 判断传入的内容是否为正常的字符串（字母和数字）
   * @param text
   * @returns {boolean} 正常的字符串为true
   */
  function isNormalStr(text: string) {
    const ORDER_NO_REG = /^[A-Za-z0-9_.\-\s]+$/g
    return !!text.match(ORDER_NO_REG)
  }

  /**
   * 正常的行内容的长度应为5-50
   * @param text
   * @returns {boolean} 正常范围内为true
   */
  function isNormalLen(text: string) {
    const LINE_CHAR_MIN = 5 // 每行最小字符数
    const LINE_CHAR_MAX = 50 // 每行最大字符数
    if (text.length >= LINE_CHAR_MIN && text.length <= LINE_CHAR_MAX) {
      return true
    } else {
      return false
    }
  }

  return {
    nums,
    setNums,
    TRACK_LENGTH,
    badgeInfo,
    getNumbers,
    filterNumbers
  }
}
