import adengine from '../ads/adengine'
import adpushup from '../ads/adpushup'
import google from '../ads/google'

let YQAD = {
  adengine: adengine(),
  google: google(),
  adpushup: adpushup(),
  queue: [],
  cbName: '',
  isLoad: false
}

// 读取配置，决定使用广告商
let currentAd = []
let advsConfig = {
  adengine: function (cb) {
    if (!window.adnginLoad) {
      // 防止重复加载
      loadJs('https://cdn.snigelweb.com/adengine/17track.net/loader.js', {
        async: true,
        defer: true
      })
      loadJs(
        'https://pixel.yabidos.com/fltiu.js?qid=63133313f533233313f573230313&cid=1027&p=1027&s=17track.com&x=&nci=&adtg=17track.com&nai=&si=&pn=&h=&w=&bp=&pp=&ci=&ip=&ai=0&di=0&mm=&os=&ua=&lat=0&lon=0&idl=&ttduid=&id5=&emh=',
        {
          async: true,
          defer: true
        }
      )
      loadJs('//btloader.com/tag?o=5658536637890560&upapi=true', {
        async: true,
        defer: true
      })
      window.adnginLoad = true
    }

    // 谷歌广告原生命名空间
    let googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []
    googletag.cmd.push(function () {
      googletag.pubads().setCentering(true)
    })

    function load() {
      cb && cb()
    }
    if (window.adngin && window.adngin.adnginLoaderReady) {
      load()
    } else {
      window.addEventListener('adnginLoaderReady', load)
    }
  },
  google: function (cb) {
    if (!window.googletagLoad) {
      // 防止重复加载
      loadJs('//securepubads.g.doubleclick.net/tag/js/gpt.js', { async: true, defer: true }, cb)
      window.googletagLoad = true
    } else {
      cb?.()
    }
  },
  adpushup: function (cb) {
    if (!window.adpushupLoad) {
      // 防止重复加载
      loadJs('//cdn.adpushup.com/42867/adpushup.js', { async: true, cross: true }, cb)
      // AdPushup广告拦截器AdBlocker
      loadJs('//delivery.adrecover.com/43084/adRecover.js', { async: true })
      window.adpushupLoad = true
    } else {
      cb?.()
    }
  }
}

/**
 *
 * @param {Array}  ads
 * @param {String} ads[].country
 * @param {String} ads[].language
 * @param {Number} ads[].rate
 * @param {Function} ads[].onLoad
 * @param {Object} opts
 */
YQAD.init = function (ads, opts) {
  YQAD.queue = []
  currentAd = []
  let adParam = getAdParam()
  // if (YQAD.isLoad) {
  //   let adCallback = opts[YQAD.cbName]
  //   // console.log(adCallback, 1)
  //   adCallback && adCallback()
  // }
  YQAD.queue.push(opts)
  if (adParam) {
    let adCallbackName = 'on' + adParam.slice(0, 1).toUpperCase() + adParam.slice(1) + 'Load'
    advsConfig[adParam](() => {
      // YQAD.isLoad = true
      YQAD.queue.forEach((item) => {
        let adCallback = item[adCallbackName]
        // console.log(adCallback, 2)
        adCallback && adCallback()
      })
    })
  } else {
    let random = Math.random()
    // 符合条件
    let accordAds = ads
    // 总比例
    let total = 0
    let prevMin = 0

    total = accordAds.reduce(function (total, item) {
      return item.percentage && item.percentage <= 1 && item.percentage > 0 ? (total += item.percentage) : total
    }, 0)

    currentAd = accordAds.filter(function (item) {
      if (item.percentage === 0) {
        return false
      }

      if (!item.percentage || item.percentage >= 1) {
        return true
      }

      let min = prevMin
      let max = (prevMin = min + item.percentage / total)

      return random > min && random <= max
    })

    currentAd.forEach(function (item) {
      let adType = item.type
      let advC = advsConfig[adType]
      let cbName = 'on' + adType.slice(0, 1).toUpperCase() + adType.slice(1) + 'Load'
      advC &&
        advC(() => {
          // YQAD.isLoad = true
          YQAD.cbName = cbName
          YQAD.queue.forEach((item) => {
            let adCallback = item[cbName]
            adCallback && adCallback()
          })
        })
    })
  }
}
// 根据url获取指定广告参数
function getAdParam() {
  let advMap = ['google', 'adengine', 'adpushup']
  let params = location.search.substring(1).split('&')
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=')
    if (pair[0] === 'addeb' && advMap.indexOf(pair[1]) > -1) {
      return pair[1]
    }
  }
  return false
}

function loadJs(url, opts, cb) {
  let script = document.getElementsByTagName('script')
  for (let i = 0; i < script.length; i++) {
    const scriptItem = script[i]
    if (scriptItem.src === 'https:' + url) {
      scriptItem.addEventListener('load', () => {
        cb && cb()
      })
      return
    }
  }

  opts = opts || {}
  let scriptEl = document.createElement('script')
  scriptEl.type = 'application/javascript'
  scriptEl.src = url

  if (opts.async) {
    scriptEl.async = true
  }

  if (opts.cross) {
    scriptEl.crossOrigin = 'anonymous'
  }

  // if (opts.defer) {
  //     scriptEl.defer = true
  // }

  scriptEl.addEventListener('load', () => {
    cb && cb()
  })

  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(scriptEl)
}

/**
 * 对document.head.appendChild方法进行重定义
 * 目的：解决国内google广告加载fundingchoicesmessages脚本慢问题
 */
if (window.YQ.country && window.YQ.country === 'CN') {
  let oldHeadAppendChild = document.head.appendChild
  document.head.appendChild = function (node) {
    if (node.src && node.src.indexOf('fundingchoicesmessages.google.com') > -1) {
      node.src = ''
    }
    oldHeadAppendChild.call(this, node)
  }
}

export default YQAD
