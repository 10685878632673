import classNames from 'classnames'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Else, If, Then, When } from 'react-if'
import TextareaAutosize from 'react-textarea-autosize'
import CustomImage from 'src/components/Image'
import useBatchNums from 'src/hooks/business/use_batch_nums'
import useI18n from 'src/hooks/use_i18n'
import { storage } from 'src/lib/storage'
import { BatchTrackScene } from 'src/types'
import CarrierModal from '../carrier_modal'
import TrackLimitModal from '../track_limit_modal'
import styles from './index.module.scss'

interface Props {
  scene?: BatchTrackScene
  autoFocus?: boolean
  height?: number // textarea 默认高度
  carrierKey?: number
  showSidebar?: boolean // 特殊不展示侧边按钮，仅展示底部查询按钮
  customPlaceholder?: string // 自定义提示文案
}

function BatchTrack(props: Props) {
  const {
    scene = BatchTrackScene.DEFAULT,
    autoFocus,
    height = 230,
    carrierKey,
    showSidebar = true,
    customPlaceholder = ''
  } = props
  const { $t, activeLocale: locale, i18nFormat } = useI18n()
  const TEXTAREA_MIN_HEIGHT = height - 36 - 4
  const [info, setInfo] = useState({} as any) // 运输商信息
  const [show, setShow] = useState<boolean>(false) // 运输商弹窗
  const [showLimitModal, setShowLimitModal] = useState<boolean>(false) // 查询运单超过40条提示
  const { nums, setNums, TRACK_LENGTH, badgeInfo, getNumbers, filterNumbers } = useBatchNums() // 批量处理运输单号
  const [numsArr, setNumsArr] = useState<string[]>([])
  const filterNumsArrRef = useRef<string[]>([]) // 过滤后运单信息
  const [focus, setFocus] = useState<boolean>(false)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  // 文案提示
  const placeholder = useMemo(() => {
    return (
      customPlaceholder ||
      `${i18nFormat($t('ResGCompYQInput.global.__inputBox_placeholder')?.replace(/。|\./g, ''), ['40'])} \n${i18nFormat(
        $t('ResGCompYQInput.global.__inputBox_airwayInputSampleTips'),
        ['123-12345678']
      )}`
    )
  }, [customPlaceholder])

  useEffect(() => {
    try {
      // 运单号多行显示
      const arr: string[] = nums ? nums?.split?.('\n') : ['']
      setNumsArr(arr)
    } catch (error) {
      console.log(error)
    }
  }, [nums])

  // 多行运单号处理
  const onInputNormalize = (value) => {
    try {
      let str = (value || '')?.toUpperCase()?.replace(/[\u4e00-\u9fa5]/g, '')
      // 转换空格和回车
      str = str.replace(/[\r]/g, '\n').replace(/[,]/g, '\n')
      if (value && value.split('\n').length > TRACK_LENGTH) {
        str = value.split('\n').slice(0, TRACK_LENGTH)?.join('\n')
      }
      return str
    } catch (error) {
      return ''
    }
  }

  // 同步用户输入
  const onInputChange = (e) => {
    const val = onInputNormalize(e.target.value)
    setNums(val)
  }

  // 重置滚动条
  const restartScroll = () => {
    textareaRef.current && (textareaRef.current.style.height = TEXTAREA_MIN_HEIGHT + 'px')
  }

  // 删除运单号
  const onDeleteItem = (idx: number) => {
    const arr = [...numsArr]
    arr.splice(idx, 1)
    window.ga?.sendEvent?.('功能操作', '全局-单行删除单号')
    setNums(arr?.join('\n'))
  }

  // 删除所有运单
  const onClearAll = () => {
    window.ga?.sendEvent?.('功能操作', '全局-清除全部单号')
    setNums('')
    restartScroll()
  }

  // 筛选运单号
  const onFilterNums = (data) => {
    window.ga?.sendEvent?.('功能操作', '全局-筛选单号')
    if (data.success) {
      const { filterNumsArr = [] } = data
      setNums(filterNumsArr?.join('\n'))
    }
  }

  // 关闭选择运输商
  const handleClose = () => {
    setShow(false)
  }

  // 重置运输商信息
  const handleReset = (e) => {
    e.stopPropagation()
    setInfo({})
  }

  // 显示选择运输商弹窗
  const handleOpenModal = () => {
    setShow(true)
  }

  // 处理查询运单信息
  const handleTrackNums = () => {
    const trackHistory = storage.get('v5_HisNumber') || []
    // @ts-ignore
    const result = [...new Set([filterNumsArrRef.current?.join(','), ...trackHistory])]
    storage.set('v5_HisNumber', result.slice(0, 10)) // 最多缓存10个单号
    window.ga?.sendEvent?.('功能操作', '全局-查询按钮', filterNumsArrRef.current.length.toString())
    window.location.href = `https://t.17track.net/${locale}#nums=${filterNumsArrRef.current?.join(',')}${
      (info.key && info.key !== '0') || carrierKey ? '&fc=' + (info.key || carrierKey) : ''
    }`
  }

  // 查询
  const handleSearch = (data) => {
    if (data.success) {
      const { filterNumsArr } = data
      filterNumsArrRef.current = filterNumsArr.slice(0, 40)

      if (filterNumsArr?.length > 40) {
        setShowLimitModal(true)
        return
      }
      handleTrackNums()
    }
  }

  return (
    <>
      <div className={classNames(styles['batch-track'], { [styles['track-brand']]: scene === BatchTrackScene.BRAND })}>
        {/* 表单区域 */}
        <div className={classNames(styles['textarea-wrapper'], { [styles.focus]: focus })} style={{ height }}>
          <div className={classNames('custom-scroll', styles['main-box'])}>
            <TextareaAutosize
              ref={textareaRef}
              id="auto-size-textarea"
              className={classNames(styles.textarea, { [styles.adjust]: numsArr.length > 99 })}
              placeholder={placeholder}
              value={nums}
              autoFocus={autoFocus}
              onChange={onInputChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            {numsArr.map((item: string, index: number) => (
              <div className={styles['number-item']} key={index}>
                <div className={styles['num-box']}>{`${index + 1}.`}</div>
                <div className={styles['main-text']}>{item}</div>
                <div
                  className={classNames('flex items-center justify-center', styles['icon-box'])}
                  onClick={() => {
                    onDeleteItem(index)
                  }}
                >
                  <When condition={nums}>
                    <i className={classNames('fa-times-circle', styles.close)}></i>
                  </When>
                </div>
              </div>
            ))}
          </div>

          {/* scene => default & sm 显示 */}
          <When condition={scene === BatchTrackScene.DEFAULT}>
            <div
              className={classNames('flex items-center justify-between gap-3.75 hidden-xs', styles['operate-tool'], {
                [styles.in]: nums
              })}
            >
              <button
                type="button"
                title={$t('ResGCompYQInput.global.__inputBox_btnFilterNumberTips')}
                onClick={() => filterNumbers(nums, onFilterNums)}
              >
                <i className="icon fa-filter"></i>
                <span>{$t('ResGCompYQInput.global.__inputBox_btnFilterNumber')}</span>
              </button>
              <button
                type="button"
                title={$t('ResGCompYQInput.global.__inputBox_btnRemoveAllTips')}
                onClick={onClearAll}
              >
                <i className="icon fa-trash-o"></i>
                <span>{$t('ResGCompYQInput.global.__inputBox_btnRemoveAll')}</span>
              </button>
            </div>
          </When>

          {/* scene => default & xs 显示 */}
          <When condition={scene === BatchTrackScene.DEFAULT}>
            <div
              className={classNames(
                'flex items-center justify-between gap-3.75 hide',
                styles['operate-tool'],
                styles.mobile,
                {
                  [styles.in]: nums
                }
              )}
            >
              <div className="flex items-center gap-2.5">
                <button
                  type="button"
                  title={$t('ResGCompYQInput.global.__inputBox_btnRemoveAllTips')}
                  onClick={onClearAll}
                >
                  <i className="icon fa-trash-o"></i>
                </button>
                <button
                  type="button"
                  title={$t('ResGCompYQInput.global.__inputBox_btnFilterNumberTips')}
                  onClick={() => filterNumbers(nums, onFilterNums)}
                >
                  <i className="icon fa-filter"></i>
                </button>
              </div>

              <If condition={info._name}>
                <Then>
                  <div
                    className={classNames('flex items-center cursor-pointer', styles['carrier-btn'])}
                    title={info?.['_name_' + locale] || info._name}
                    onClick={handleOpenModal}
                  >
                    <CustomImage
                      isWebp={false}
                      src={`https://res.17track.net/asset/carrier/logo/34x34/${info.key}`}
                      alt="17TRACK"
                      width={30}
                      height={30}
                      errorSrc="https://res.17track.net/asset/carrier/logo/34x34/0"
                    />
                    <span className={classNames(styles.name, 'line-clamp-1')}>
                      {info?.['_name_' + locale] || info._name}
                    </span>
                    <i className="fa-times-circle" onClick={(e) => handleReset(e)}></i>
                  </div>
                </Then>
                <Else>
                  <button
                    className={classNames('flex items-center gap-2.5 p-2')}
                    title={$t('ResWwwPHome.global.__track_selectCarrierBtnTips')}
                    onClick={handleOpenModal}
                  >
                    <i className={classNames('fa-truck', styles.truck)}></i>
                    <span>{$t('ResWwwPHome.global.__track_selectCarrierBtnDefaultText')}</span>
                  </button>
                </Else>
              </If>
            </div>
          </When>

          {/* scene => brand 显示 */}
          <When condition={scene === BatchTrackScene.BRAND}>
            <div
              className={classNames('flex items-center justify-end', styles['operate-tool'], {
                [styles.in]: nums
              })}
            >
              <div className="flex items-center gap-2.5">
                <button
                  type="button"
                  title={$t('ResGCompYQInput.global.__inputBox_btnFilterNumberTips')}
                  onClick={() => filterNumbers(nums, onFilterNums)}
                >
                  <i className="icon fa-filter"></i>
                </button>
                <button
                  type="button"
                  title={$t('ResGCompYQInput.global.__inputBox_btnRemoveAllTips')}
                  onClick={onClearAll}
                >
                  <i className="icon fa-trash-o"></i>
                </button>
              </div>
            </div>
          </When>
        </div>

        {/* 查询区域 */}
        {showSidebar && (
          <div className={classNames('flex items-center justify-center', styles['track-wrapper'])}>
            <When condition={scene === BatchTrackScene.DEFAULT}>
              <div className={classNames('flex flex-col items-center gap-2.5 ml-2.5 hidden-xs')}>
                <If condition={info._name}>
                  <Then>
                    <div
                      className={classNames(
                        'flex flex-col items-center justify-center cursor-pointer',
                        styles['carrier-area'],
                        styles.selected
                      )}
                      title={info?.['_name_' + locale] || info._name}
                      onClick={handleOpenModal}
                    >
                      <CustomImage
                        isWebp={false}
                        src={`https://res.17track.net/asset/carrier/logo/120x120/${info.key}`}
                        alt="17TRACK"
                        width={110}
                        height={110}
                        errorSrc="https://res.17track.net/asset/carrier/logo/120x120/0"
                      />
                      <button
                        className={classNames(styles['close-btn'])}
                        title={$t('ResGWord.global.__other_clear')}
                        onClick={(e) => handleReset(e)}
                      >
                        <i className="fa-clear"></i>
                      </button>
                    </div>
                  </Then>
                  <Else>
                    <div
                      className={classNames(
                        'flex flex-col items-center justify-center cursor-pointer py-2.5 px-2 hidden-xs',
                        styles['carrier-area']
                      )}
                      title={$t('ResWwwPHome.global.__track_selectCarrierBtnTips')}
                      onClick={handleOpenModal}
                    >
                      <i className={classNames('fa-truck', styles.icon)}></i>
                      <span className="line-clamp-1">{$t('ResWwwPHome.global.__track_selectCarrierBtn')}</span>
                      <span className={classNames('line-clamp-1', styles['auto-ident'])}>
                        {$t('ResWwwPHome.global.__track_selectCarrierBtnDefaultText')}
                      </span>
                    </div>
                  </Else>
                </If>
                <div
                  className={classNames(
                    'flex flex-col items-center justify-center cursor-pointer p-2.5 hidden-xs',
                    styles['search-area']
                  )}
                  title={$t('ResWwwPHome.global.__track_trackBtnTips')}
                  onClick={() => getNumbers(nums, handleSearch)}
                >
                  <i className={classNames('fa-yqtrack', styles.icon)}></i>
                  <span className="leading-6 user-select-none">{$t('ResWwwHomePage.global.__track_trackBtn')}</span>
                  <When condition={badgeInfo.show}>
                    <span className={styles.badge}>{badgeInfo.num}</span>
                  </When>
                </div>
              </div>
            </When>

            <div
              className={classNames(
                'flex items-center justify-center cursor-pointer p-2.5',
                styles['search-area'],
                styles.mobile,
                { hide: scene === BatchTrackScene.DEFAULT }
              )}
              title={$t('ResWwwPHome.global.__track_trackBtnTips')}
              onClick={() => getNumbers(nums, handleSearch)}
            >
              <i className={classNames('fa-yqtrack', styles.icon)}></i>
              <span className="leading-6 user-select-none">{$t('ResWwwHomePage.global.__track_trackBtn')}</span>
              <When condition={badgeInfo.show}>
                <span className={styles.badge}>{badgeInfo.num}</span>
              </When>
            </div>
          </div>
        )}
      </div>

      {!showSidebar && (
        <div className="text-center">
          <div
            className={classNames(
              'cursor-pointer btn btn-block btn-primary waves-effect',
              styles['search-area-bottom']
            )}
            title={$t('ResWwwPHome.global.__track_trackBtnTips')}
            onClick={() => getNumbers(nums, handleSearch)}
          >
            <i className={classNames('fa-yqtrack', styles.icon)}></i>
            <span className="leading-6 user-select-none">{$t('ResWwwHomePage.global.__track_trackBtn')}</span>
            <When condition={badgeInfo.show}>
              <span className={styles.badge}>{badgeInfo.num}</span>
            </When>
          </div>
        </div>
      )}

      {/* 选择运输商 */}
      <When condition={show}>
        <CarrierModal show={show} onClose={handleClose} info={info} setInfo={setInfo} />
      </When>

      {/* 查询运单超过40条提示 */}
      <When condition={showLimitModal}>
        <TrackLimitModal
          show={showLimitModal}
          onClose={() => setShowLimitModal(false)}
          handleTrackNums={handleTrackNums}
        />
      </When>
    </>
  )
}

export default memo(BatchTrack)
