// google gpt 文档 // https://developers.google.com/publisher-tag/guides/get-started

// 谷歌广告原生命名空间
// var googletag   = window.googletag || {}
// googletag.cmd   = googletag.cmd || []
// console.log('0', googletag)
let googletag = {}
export default function () {
  let AD = {}
  AD.oSlots = {}
  // allen [2019-12-24]
  // 增加标记，控制GD中的行为上报统计
  var _stopEvents = false
  var targeting = {}

  // 触发方法
  AD.stopEvent = function () {
    _stopEvents = true
  }

  AD.setTargeting = function (key, value) {
    targeting[key] = value
  }

  /**
   * [init 初始化广告]
   * @param  {[type]} arrDa [description]
   * @return {[type]}       [description]
   */
  AD.init = function (arrDa) {
    googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []
    // 当前页面词言
    this.lang = document.querySelector('html').lang
    for (var i = 0, len = arrDa.length; i < len; i++) {
      var daItem = arrDa[i]
      AD.addItem(daItem)
    }
    this.initDisplay(arrDa)
    this.resize(arrDa)
  }

  AD.initDisplay = function (arrDa) {
    googletag.cmd.push(function () {
      var pubads = googletag.pubads()

      // 开启单个请求多个广告
      //pubads.enableSingleRequest()

      // 禁止初始化时请求广告
      pubads.disableInitialLoad()

      // 设置广告居中显示
      pubads.setCentering(true)

      // 是否设置简体中文,{是:1,否:0}
      var isCN = AD.lang === 'zh-cn' ? '1' : '0'
      pubads.setTargeting('IsCN', isCN)
      // 语言
      pubads.setTargeting('lang', (window.YQ.configs && window.YQ.configs.lang) || '')
      // 省份
      pubads.setTargeting('province', (window.YQ.loc && window.YQ.loc.province) || '')
      // 标识新版本广告
      pubads.setTargeting('isNew', '2024-05-09')

      // 引用域
      var urlRef = document.referrer
      var referrer = typeof urlRef === 'string' && urlRef.match(/^https?:\/\/([^:/\\]+)/)
      if (referrer) {
        pubads.setTargeting('Referrer', referrer[1])
      }

      // 开启广告服务
      googletag.enableServices()

      // 显示广告
      for (var i = 0, len = arrDa.length; i < len; i++) {
        var daItem = arrDa[i]
        googletag.display(daItem.divId)
      }

      // 记录到谷歌分析 [DA, "广告ID"+"(Init/Auto/Change)", 728x90, 1/0]
      pubads.addEventListener('slotRenderEnded', function (event) {
        // console.log(event)
        try {
          var slotId = event.slot.getSlotElementId() + '(' + event.slot.yqAction + ')'
          var size = event.size?.[0] + 'x' + event.size?.[1]
          var isEmpty = event.isEmpty ? 1 : 0

          if (!_stopEvents) {
            window.ga?.sendEvent?.('DA', slotId, size, isEmpty) // GA上报事件待处理
          }
        } catch (error) {
          console.log(error)
        }
      })
    })
  }

  AD.addItem = function (opts) {
    googletag.cmd.push(function () {
      //定义尺寸映射
      var mappinList = opts.mapping
      var mappingBuilder = googletag.sizeMapping()
      for (var i = 0, len = mappinList.length; i < len; i++) {
        // console.log(...mappinList[i].slotSize)
        mappingBuilder.addSize(mappinList[i].viewSize, mappinList[i].slotSize)
      }
      //   console.log(mappingBuilder)
      //创建尺寸映射
      var mapping = mappingBuilder.build()
      //   console.log(mapping)
      //定义广告
      var oDa = document.querySelector('#' + opts.divId)
      var adWidth = oDa?.offsetWidth || 0
      var adHeight = oDa?.offsetHeight || 0

      var slot = googletag.defineSlot(opts.slotId, [adWidth, adHeight], opts.divId) //第一个尺寸作为默认尺寸
      slot.setTargeting('refresh', opts.divId) // 调整刷新逻辑
      slot.defineSizeMapping(mapping) //设置尺寸映射
      slot.addService(googletag.pubads()) //添加服务

      // 将slot挂在YQG.DA下，方便使用
      var oSlot = (AD.oSlots[opts.divId] = {})
      if (!window.googletag.slots) {
        // slot挂载在全局方便卸载
        window.googletag.slots = []
      }
      window.googletag.slots.push(slot)
      oSlot.slot = slot
      oSlot.hadFresh = false
      if (opts.interval < 0 || typeof opts.interval !== 'number') {
        oSlot.intervalTime = 0
      } else {
        oSlot.intervalTime = opts.interval
      }

      oDa = document.querySelector('#' + opts.divId)
      // 记录初次广告容器宽高
      oSlot.proAdWidth = oDa?.offsetWidth || 0
      oSlot.proAdHeight = oDa?.offsetHeight | 0

      // 添加刷新方法
      googletag.pubads().addEventListener('impressionViewable', function (event) {
        // 清空定时
        var slot = event.slot
        if (slot.getTargeting('refresh').indexOf(opts.divId) > -1) {
          setTimeout(function () {
            googletag.pubads().refresh([slot])
          }, oSlot.intervalTime * 1000)
        }
      })
    })
  }

  /**
   * [refresh 刷新广告]
   * @param  {Array} arrDa    [广告数组]
   * @return {[type]}         [description]
   */
  AD.refresh = function (arrDa, yqAction) {
    googletag.cmd.push(function () {
      arrDa.forEach(function (item) {
        AD.refreshDA(item, yqAction)
      })
    })
  }

  AD.refreshDA = function (item, yqAction) {
    var oSlot = AD.oSlots[item.divId]

    // 清空定时
    // clearInterval(oSlot.interval)
    // oSlot.interval = null

    // 刷新广告
    if (googletag && googletag.apiReady && googletag.pubadsReady) {
      oSlot.slot.yqAction = yqAction || 'Init'

      googletag.pubads().setTargeting('LoadType', oSlot.slot.yqAction)

      for (var key in targeting) {
        googletag.pubads().setTargeting(key, targeting[key])
      }

      googletag.pubads().refresh([oSlot.slot])
    }

    // 启动定时
    // if (oSlot.intervalTime !== 0) {
    //     oSlot.interval = AD.setInterval(item)
    // }

    // 标识允许刷广告，resize时用到
    oSlot.hadFresh = true
  }

  AD.resize = function (arrDa) {
    googletag.cmd.push(function () {
      var fnResize = function (opts) {
        var oDa = document.querySelector('#' + opts.divId)
        var adWidth = oDa.offsetWidth
        var adHeight = oDa.offsetHeight
        var oSlot = AD.oSlots[opts.divId]

        // mapping.length>1才刷
        if (adWidth != oSlot.proAdWidth || adHeight != oSlot.proAdHeight) {
          if (oSlot.hadFresh) {
            AD.refresh([opts], 'Change')
          }
          oSlot.proAdWidth = adWidth
          oSlot.proAdHeight = adHeight
        }
      }

      window.onresize = function () {
        for (var i = 0, len = arrDa.length; i < len; i++) {
          var item = arrDa[i]
          var oDa = document.querySelector('#' + item.divId)
          if (item.mapping.length > 1 && oDa) {
            fnResize(item)
          }
        }
      }
    })
  }

  /**
   * [initRefresh 初始并刷新广告]
   * @param  {Array} arrDa  [广告数组]
   * @return {[type]}       [description]
   */
  AD.initRefresh = function (arrDa) {
    // 清除window上缓存的slot
    if (window.googletag && window.googletag.slots && window.googletag.slots.length) {
      window.googletag.destroySlots(window.googletag.slots)
      window.googletag.slots = []
    }
    this.init(arrDa)
    this.refresh(arrDa)
  }

  return AD
}
