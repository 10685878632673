// adpushup官方 未提供任何开发文档
// 简单提要：
// adpushup 基于 google adm 扩展型合作伙伴管理(SPM)
// SPM 相关说明 https://support.google.com/admanager/topic/7527207?visit_id=1628733979367-8536649066731557429&rd=1
// gpt 开发文档 https://developers.google.com/publisher-tag/guides/get-started
// 所以基础思路就是基于 gpt进行开发的。sizeMapping由adpushup那边进行处理适配

window.adpushup = window.adpushup || {
  que: []
}

export default function () {
  let AD = {}

  AD.refresh = function (arrDa) {
    window.adpushup.que.push(function () {
      arrDa.forEach(function (item) {
        window.adpushup.triggerAd(item.divId)
      })
    })
  }

  return AD
}
