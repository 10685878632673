import jsCookie from 'js-cookie'
import { keyBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { IKV } from 'src/types'
import useI18n from '../use_i18n'

export default function useCarrierHistory() {
  const { $t } = useI18n()
  const [carrierHistoryList, setCarrierHistoryList] = useState<IKV[]>([])

  // 获取搜索历史
  const getCarrierHistoryList = () => {
    try {
      const historyCookie = jsCookie.get('v5_HisExpress')

      const resExpressGroup = $t('ResGExpressGroup.items')
      const resGCarrier = keyBy(window.ResGCarrier || [], 'key')

      let codeList, carrier, groupItem, len
      let i = 0
      const keys: any[] = []

      if (historyCookie) {
        codeList = historyCookie.split('_')

        for (len = codeList.length; i < len; i++) {
          carrier = resGCarrier[codeList[i]]

          if (!carrier) {
            continue
          }
          // 是快递
          if (carrier._group) {
            // 快递组项
            groupItem = resExpressGroup.itemsDict.get(carrier._group)
            // 是可以显示的
            if (groupItem && groupItem._hideSelect == 'false') {
              keys.push(codeList[i])
            }
          } else {
            // 是邮政
            keys.push(codeList[i])
          }
        }
        const result = keys.map((itemKey: string) => window.YQ.$carriers.getCarrier?.(itemKey) || {})
        setCarrierHistoryList(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 选中运输商传入carrierKey保存到Cookie
  const cacheCarrierHistory = (carrierKey) => {
    if (carrierKey === '0') {
      return
    }

    const historyCookie = jsCookie.get('v5_HisExpress')
    const carrierCookieArr = historyCookie ? historyCookie.split('_') : []
    const _index = carrierCookieArr && carrierCookieArr.indexOf(carrierKey)

    if (_index < 0) {
      // 在Cookie记录中没有找到传入key
      carrierCookieArr.unshift(carrierKey)
    } else {
      // 已存在cookie记录的情况下
      // 删除重复的项,并重新追加到数组最前面
      carrierCookieArr.splice(_index, 1)
      carrierCookieArr.unshift(carrierKey)
    }

    const result = carrierCookieArr.slice(0, 8)

    //保存cookie;
    jsCookie.set('v5_HisExpress', result?.join('_'), { expires: 365, path: '/', domain: '17track.net' })
  }

  useEffect(() => {
    getCarrierHistoryList()
  }, [])

  return {
    carrierHistoryList,
    cacheCarrierHistory
  }
}
