import { useDebounce, useSetState, useThrottleFn } from 'ahooks'
import { cloneDeep } from 'lodash-es'
import { useEffect } from 'react'

interface SearchInfo {
  inputVal: string
  dataSource: any[]
  showList: any[]
  hasMore: boolean
}

export default function useBatchCarrier() {
  // 搜索信息
  const [searchInfo, setSearchInfo] = useSetState<SearchInfo>({
    inputVal: '',
    dataSource: [],
    showList: [],
    hasMore: true
  })

  const debouncedValue = useDebounce(searchInfo.inputVal, { wait: 100 })

  // 加载更多数据
  const { run: fetchMoreData } = useThrottleFn(
    () => {
      const { dataSource, showList, hasMore } = searchInfo
      if (hasMore) {
        const _dataSource = cloneDeep(dataSource)
        const result = _dataSource.splice(0, 60) // 每次加载60条数据

        setSearchInfo({ dataSource: _dataSource, showList: showList.concat(result), hasMore: !!_dataSource.length })
      }
    },
    { wait: 500 }
  )

  useEffect(() => {
    try {
      const dataSource = debouncedValue
        ? window.YQ.$carriers.searchCarrier(debouncedValue)
        : window.YQ.$carriers.getCarrierList()
      const result = dataSource.splice(0, 60)
      setSearchInfo({ dataSource, showList: result, hasMore: !!dataSource.length })
    } catch (error) {
      console.log(error)
    }
  }, [debouncedValue])

  return {
    debouncedValue,
    searchInfo,
    setSearchInfo,
    fetchMoreData
  }
}
