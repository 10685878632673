import Image, { ImageProps } from 'next/image'
import { memo, useState } from 'react'

function CarrierImage(props: ImageProps) {
  const { src, ...resetProps } = props
  const [url, setUrl] = useState<string>(src as string)

  return (
    // 运输商logo兜底
    <Image {...resetProps} src={url} onError={() => setUrl('https://res.17track.net/asset/carrier/logo/34x34/0.png')} />
  )
}

export default memo(CarrierImage)
