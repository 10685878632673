import { useEffect, useState } from 'react'

// 获取热门运输商列表
export default function useCarrierHot() {
  const [carrierHotList, setcarrierHotList] = useState<any[]>([])

  function getHotCarrierList() {
    try {
      const keys = ['21051', '190271', '100003', '7047', '100001', '100005', '190112', '190094', '100007']
      const result = keys.map((itemKey: string) => window.YQ.$carriers.getCarrier?.(itemKey) || {})

      setcarrierHotList(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getHotCarrierList()
  }, [])

  return {
    carrierHotList
  }
}
