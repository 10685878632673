import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import useI18n from 'src/hooks/use_i18n'
import styles from './index.module.scss'

interface Props {
  show: boolean
  onClose: () => void
  handleTrackNums: () => void
}

export default function TrackLimitModal(props: Props) {
  const { show, onClose, handleTrackNums } = props
  const { $t, i18nFormat } = useI18n()

  // 关闭弹窗
  const handleClose = () => {
    onClose()
  }

  // 确认
  const handleConfirm = () => {
    handleTrackNums()
    handleClose()
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName={styles['dialog-track-limit']}
      contentClassName={styles['modal-content']}
    >
      <div className={classNames('flex items-center justify-between', styles['modal-header'])}>
        <h4 className={classNames('line-clamp-1')}>{$t('ResGCompYQInput.global.__dialogExceed_title')}</h4>
        <div className={classNames('flex items-center')}>
          <span className={classNames('flex items-center justify-center', styles.close)} onClick={handleClose}>
            ×
          </span>
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles.desc}>{i18nFormat($t('ResGCompYQInput.global.__dialogExceed_content'), [40])}</div>
        <div className="text-right">
          <button className={classNames('cursor-pointer', styles.cancel)} onClick={handleClose}>
            {$t('ResGCompYQInput.global.__dialogExceed_btnCancel')}
          </button>
          <button className={classNames('cursor-pointer', styles.confirm)} onClick={handleConfirm}>
            {$t('ResGCompYQInput.global.__dialogExceed_btnConfirm')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
