import { IKV } from '../types'

export function store<S extends IKV>() {
  return {
    get: function <K extends keyof S>(key: K): null | S[K] {
      const value = localStorage.getItem(String(key))
      if (value === null) return null
      try {
        return JSON.parse(value) as null | S[K]
      } catch (error) {
        return value as null | S[K]
      }
    },
    set: function <K extends keyof S>(key: K, value: S[K]) {
      localStorage.setItem(String(key), typeof value === 'string' ? value : JSON.stringify(value))
    },
    del: function <K extends keyof S>(key: K) {
      localStorage.removeItem(String(key))
    },
    clear: function () {
      localStorage.clear()
    },
    session: {
      get: function <K extends keyof S>(key: K): null | S[K] {
        const value = sessionStorage.getItem(String(key))
        if (value === null) return null
        try {
          return JSON.parse(value) as null | S[K]
        } catch (error) {
          return value as null | S[K]
        }
      },
      set: function <K extends keyof S>(key: K, value: S[K]) {
        sessionStorage.setItem(String(key), typeof value === 'string' ? value : JSON.stringify(value))
      },
      del: function <K extends keyof S>(key: K) {
        sessionStorage.removeItem(String(key))
      },
      clear: function () {
        sessionStorage.clear()
      }
    }
  }
}

export interface IStore {
  // 历史查单记录
  v5_HisNumber: string[]
}

export const storage = store<IStore>()
