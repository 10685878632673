// AdEngine 文档 https://doc.snigel.com/
// sizeMapping由AdEngine那边进行处理适配

export default function () {
  let AD = {}

  AD.refresh = function (arrDa) {
    window.adngin.queue.push(function () {
      window.adngin.cmd.startAuction(arrDa)
    })
  }

  return AD
}
